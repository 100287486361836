import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backgroundImage from "../../boder-left.jpg";
import backgroundtopImage from "../../boder-top.jpg";
const AboutUs = () => {
  const [page, setPage] = useState({});
  useEffect(() => {
    
    axios.get(`https://onethirdlive.smacdemo.in/api/pages/about-us`)
      .then(response => {
        setPage(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);
  return (
    <>
    <section className="mainBanner image-container">  
        
        <div  className="swiper-slide">
          <img src="https://onethirdlive.smacdemo.in/storage/app/public/sliders/20230619190024.png" alt="About Us" />
          <div className="overlay"></div>          
            <div className="banner-text">  About Us </div>
          
        </div>
    </section>  
    <section id="borderimg1" style={{
        borderWidth: '0 32px',
                      background: `
                  url(${backgroundImage}) repeat-y left top, 
                  url(${backgroundImage}) repeat-y right top,
                  url(${backgroundtopImage}) repeat-x left top,
                  url(${backgroundtopImage}) repeat-x left bottom 
                 
                `,          
                backgroundPosition: 'left right top bottom', 
      backgroundRepeat: 'repeat-y repeat-x', 
  
  }}>
          <div className="top-border"></div>
      <div className="right-border"></div>

        <div className='container'>
          <div className='row'>
              <div className='col-md-12 pt-2 text-center'>
              <div dangerouslySetInnerHTML={{ __html: page.description }} />

          </div>
        </div>
        </div>
    </section>
    </>
    );
}
export default AboutUs;
