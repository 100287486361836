import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Shimmer } from 'react-shimmer';


const LatestBlogs = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get("https://onethirdlive.smacdemo.in/api/getposts?type=1&count=2")
      .then((response) => {
        setPosts(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  if (loading) {
    // Render skeleton screen or loading indicator here
    return <div>
                          <Shimmer width={500} height={300} duration={1000}>
                     
                    </Shimmer>

    </div>;
  }

  return (
    <>
      <section id="latestblog" className="latest-blog pb-0" data-aos="zoom-in" data-aos-delay='200'>
        <div className="container-fluid">
          <div className="col-lg-12 m-auto d-flex justify-content-center align-items-center mb-5">
            <div className="row">
              <div className="col-lg-2 d-none d-lg-block">
                {" "}
                <img
                  src="assets/img/blog-left-icon.png"
                  alt="one third"
                  className="img-fluid"
                />{" "}
              </div>
              <div className="col-lg-8 d-flex justify-content-center align-items-center">
                <h2> Latest Blogs </h2>
              </div>
              <div className="col-lg-2 d-none d-lg-block">
                {" "}
                <img
                  src="assets/img/blog-right-icon.png"
                  className="img-fluid"
                  alt="one third"
                />{" "}
              </div>
            </div>
          </div>

          <div className="row">
            {posts.map((post) => (
              <div className="col-lg-6 col-md-6" key={post.id}>
                <div className="lpost position-relative">
                  <Link to={`/blog/${post.slug}`}>
                    <img
                      src={post.latest_image}
                      className="img-fluid"
                      alt={post.title}
                    />
                  </Link>
                  <div className="post-title"> {post.title} </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default LatestBlogs;
