import React, { useEffect, useState } from 'react';
import axios from 'axios';
import backgroundImage from "../boder-left.jpg";
import backgroundtopImage from "../boder-top.jpg";
const Post = (props) => {
  const [post, setPost] = useState({});

  useEffect(() => {
    const postslug = props.match.params.slug;
    axios.get(`https://onethirdlive.smacdemo.in/api/posts/${postslug}`)
      .then(response => {
        setPost(response.data.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    <>
    <section className="mainBanner image-container">  
        
        <div  className="swiper-slide">
          <img src={post.image}  alt={post.title} />
          <div class="overlay"></div>          
            <div className="banner-text">  {post.title} </div>
          
        </div>
    </section>  
    <section id="borderimg1" style={{
        borderWidth: '0 32px',
                      background: `
                  url(${backgroundImage}) repeat-y left top, 
                  url(${backgroundImage}) repeat-y right top,
                  url(${backgroundtopImage}) repeat-x left top,
                  url(${backgroundtopImage}) repeat-x left bottom 
                 
                `,          
                backgroundPosition: 'left right top bottom', 
      backgroundRepeat: 'repeat-y repeat-x', 

    
       
       

        
       
  
  
  }}>
          <div className="top-border"></div>
      <div className="right-border"></div>

        <div className='container'>
          <div className='row'>
              <div className='col-md-12 pt-5'>
              <div dangerouslySetInnerHTML={{ __html: post.description }} />

              <ul>
                {post.tags && post.tags.split(',').map((tag, index) => (
                  <li key={index}>{tag.trim()}</li>
                ))}
              </ul>
              </div>
          </div>
        </div>
    </section>
    
    </>
  );
}

export default Post;
