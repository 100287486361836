import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);


const MoreStories = () => {
  const [moreStories, setmoreStories] = useState([]);
  const isMobile = window.innerWidth < 768; // adjust breakpoint as needed

  const slidesPerView = isMobile ? 1 : 4;

  useEffect(() => {
    axios
      .get("https://onethirdlive.smacdemo.in/api/getposts?type=3&count=8")
      .then((response) => {
        setmoreStories(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
    
<section className="more-store" data-aos="fade-up">
  <div className="container-fluid p-0 m-0">

    <h2 className="mb-5"> More Store </h2>

    <div className="more-store-slider position-relative">

      <div className="more-banner swiper">
        <div className="swiper-wrapper">
        <Swiper
      navigation= {false}
      pagination={ false }
      autoplay={{ delay: 3000 }}
      slidesPerView={slidesPerView}
      spaceBetween={10}
      slideWidth={200} // Add the slide width here
      breakpoints={{
        // when window width is >= 768px
        768: {
          slidesPerView: 4,
        },
      }}     
    >
            {moreStories.map((post) => ( 
            <SwiperSlide>
          <div className="swiper-slide">
            <div className="position-relative">
            <Link to={`/blog/${post.slug}`} >
              <img src={post.story_image} className="img-fluid" alt={post.title} />
              <div className="slider-content">
                <div className="row">
                  <div className="col-lg-8">
                    <h3>{post.title} </h3>
                  </div>
                  <div className="col-lg-4">
                    <p> By Ronald Richards </p>
                    <p className="play-icon"> <i className='bx bxs-caret-right-circle'></i> </p>
                  </div>
                </div>
              </div>
            </Link>
            </div>
          </div>
          </SwiperSlide>
            ))}
          </Swiper>
        </div>
       
      </div>
      
    </div>
  </div>
</section>
    
    </>
  );
};

export default MoreStories;
