import React, { useState, useEffect } from "react";
import axios from "axios";
import Head from "./common/Head";
import HomeSlider from "./common/HomeSlider";
import Header from "./common/Header";
import MoreStories from "./common/MoreStories";
import TopBlogs  from "./common/TopBlogs";
import { Link } from "react-router-dom";

import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);
const Category = ({ match }) => {
    const [categoryData, setCategoryData] = useState([]);
    const [category_id, setCategoryId] = useState([]);
    const [category_name, setCategoryName] = useState([]);
    const[categoryimage, setCategoryImage] = useState([]);
    const [posts, setPosts] = useState([]);
    const [subcategoriesData, setsubcategoriesData]= useState([]);
    const [data, setData] = useState([]);
    const isMobile = window.innerWidth < 768; // adjust breakpoint as needed

    const slidesPerView = isMobile ? 1 : 4;
    useEffect(() => {
        axios
          .get(`https://onethirdlive.smacdemo.in/api/categories-data/${match.params.slug}`)
          .then((response) => {
            setData(response.data.data);

            setCategoryData(response.data.data);
            setCategoryId(response.data.data[0].id);
            setCategoryName(response.data.data[0].title);
            setCategoryImage(response.data.data[0].categoryimage);
            setPosts(response.data.posts);
            setsubcategoriesData(response.data.data.subcategories);
           
          })
          .catch((error) => {
            console.log(error);
          });
      }, [match.params.slug]);


  
    return (
    <>   
      
      <section className="mainBanner p-0 m-0">          
        <div  className="swiper-slide">
          <img src={categoryimage}  alt={category_name} />
          
            <div className="banner-text">  {category_name} </div>
          
        </div>
    </section>   
    
    <section className="storires-tab pb-0">

    <div className="col-lg-10 m-auto text-center"> 
     <div className="row tabsmenu position-relative">
            <div className="col-lg-6 tabsmenuli borderline col-6"> 
              <a href="#"> China <img src="../assets/img/china-flag-icon.jpg" alt="" /> </a>  </div>
            <div className="col-lg-6 tabsmenuli col-6">  
              <a href=""> India <img src="../assets/img/india-flag-icon.jpg" alt="" /> </a> </div>
     </div>
    </div>  
     <hr />
     
        
       </section>
       
       <MoreStories />
       <TopBlogs category_id={category_id} />
       {data.map(category => (
        
          <>
            {category.subcategories.map(subcategory => (

              <section className="more-store telecom">
          <div className="container-fluid">
            <h2 className="mb-5"> {subcategory.title} </h2>
            <div className="more-store-slider position-relative">
              <div className="telecom-banner swiper">
              <Swiper
      navigation= {false}
      pagination={ false }
      autoplay={{ delay: 3000 }}
      slidesPerView={slidesPerView}
      spaceBetween={10}
      breakpoints={{
        // when window width is >= 768px
        768: {
          slidesPerView: 4,
        },
      }}

    >
              
                  {subcategory.posts.map(post => (
                    <SwiperSlide>
                       <div className="swiper-slide" key={post.id}>
                    <div className="position-relative">
                    <Link 
        to={`/blog/${post.slug}`} >
                      <img src={post.type_image} className="img-fluid" alt={post.title} />
                      <div className="slider-content p-2">
                        <p className="author"> 
                          <img src="../assets/img/rahul-swami-photo.png" className="img-fluid pe-2" /> Rahul Swami, July12 </p>
                            <h3> {post.title}</h3>
                            <p dangerouslySetInnerHTML ={{ __html: (post.description.replace(/(<([^>]+)>)/gi, '').slice(0, 100))}}></p>
                      </div>
                    </Link>
                    </div>
                  </div>
                    
                     </SwiperSlide>
                  ))}
                  </Swiper>
              </div>
              </div>
              </div>
              </section>
            ))}
          </>
        
      ))}

           
  </>
);
};

export default Category;

