import React from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
    <footer id="footer" className="bg-body">
    <div className="footer-top">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-3 footer-links text-start col-12">
            <h3> About us </h3>
            <p className="footercontent">The vision of One Third is to bring the world closer to China and India by bridging the gap of knowledge regarding China and India. We are here to provide well researched ground information that will help change the traditional narrative of one-sided and largely negative perspective about the ONE THIRD of the world’s population.</p>
          </div>
          <div className="col-lg-3 col-md-3 footer-links col-6 text-center">
            <ul>
              <li> <Link className="nav-link" to={`/category/education`}> Education </Link></li>
              <li> <Link className="nav-link" to={`/category/statistics`}>  Statistics </Link></li>
              <li> <Link className="nav-link" to={`/category/fifestyle`}>  Lifestyle </Link></li>
              <li> <Link className="nav-link" to={`/about-us`}>  About Us </Link></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 footer-links col-6">
            <ul>
              <li> <Link className="nav-link" to={`/category/business`}>  Business  </Link></li>
              <li> <Link className="nav-link" to={`/category/culture`}>  Culture  </Link></li>
              <li><Link className="nav-link" to={`/category/travel`}>  Travel  </Link></li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-3 footer-links text-center">
            <p className="text-center footercontent">
              “Got new ideas for us? <br />
              We would love to hear them. <br />
              Let’s get in touch” <br />
              info@onethird.com </p>
            <div className="social-links mt-3">
              <a href="#" className="instagram"> <i className='bx bxl-instagram'></i> </a>
              <a href="#" className="facebook"> <i className='bx bxl-facebook'></i> </a>
              <a href="#" className="twitter" target="_blank"> <i className='bx bxl-twitter'></i> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i
      className="bi bi-arrow-up-short"></i></a>
    <script src="assets/vendor/purecounter/purecounter_vanilla.js"></script>
    <script src="assets/vendor/aos/aos.js"></script>
    <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
    <script src="assets/vendor/glightbox/js/glightbox.min.js"></script>
    <script src="assets/vendor/isotope-layout/isotope.pkgd.min.js"></script>
    <script src="assets/vendor/swiper/swiper-bundle.min.js"></script>
    <script src="assets/vendor/php-email-form/validate.js"></script>
  
    <script src="assets/js/main.js"></script>
  
    </>
  );
};

export default Footer;
