import React, { useState, useEffect } from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';
import static_banner from '../../main-banner.png';
import static_banner_mobile from '../../mobile-banner.jpg';
 
import axios from 'axios';

const MainSlider = () => {
  const [slides, setSlides] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await axios.get('https://onethirdlive.smacdemo.in/api/sliders');
        setSlides(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching slides:', error);
      }
    };

    fetchSlides();
  }, []);
 
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const renderBullet = (index, className) => {
    return `
      <span class="${className}">
        <span class="slider-title">${slides[index]?.title || ''}</span>
      </span>
    `;
  };

  const params = {
    loop: true,
    autoplay: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      renderBullet: renderBullet,
      bulletClass: 'slider-pagination-bullet',
      bulletActiveClass: 'slider-pagination-bullet-active',
      bulletElement: 'span',
      wrapperClass: 'pagination-wrapper',
    },
    on: {
      init: (swiper) => {
        const bullets = swiper.pagination.bullets;
        bullets.forEach((bullet, index) => {
          bullet.addEventListener('mouseenter', () => {
            swiper.slideTo(index);
          });
        });
      },
    },
  };

  return (
    <>
      <section className="mainBanner">
        {slides.length > 0 ? (
          <Swiper {...params}>
            {slides.map((slide) => (
              <div key={slide.id}>
                <Link to={`${slide.link}`}>
                  <img src={isMobile ? slide.mobile_image : slide.image} alt={slide.title} />
                </Link>
                <p className="banner-content one">{slide.short_description}</p>
              </div>
            ))}
          </Swiper>
        ) : (
          <div>
            <Link to="/">
              <img src={isMobile ? static_banner_mobile : static_banner} alt="Default Slide" />
            </Link>
            <p className="banner-content one">Loading...</p>
          </div>
        )}
      </section>
    </>
  );
};

export default MainSlider;
