import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Home from './components/pages/Home';
import Category from './components/Category';
import Post from './components/Post';
import AboutUs from './components/pages/AboutUs';
import ContactUs from './components/pages/ContactUs';
import TermsConditions from './components/pages/TermsConditions';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Head from './components/common/Head';
import loaderGif from "./loader.gif";
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init(); // Initialize AOS
    setTimeout(() => {
      setLoading(false);
    }, 3000);
   

  }, []);

  return (
    <Router>
      {loading ? (
        <div className={`loader-container ${loading ? "fadeIn" : "fadeOut"}`}>
          <img src={loaderGif} alt="loader" className="loader-gif"  />
         

        </div>
      ) : (
        <>
          <Head />
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/category/:slug" component={Category} />
            <Route path="/blog/:slug" component={Post} />
            <Route path="/about-us" component={AboutUs} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/terms-and-conditions" component={TermsConditions} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
          </Switch>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;
