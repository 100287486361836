import React, { useState, useEffect } from "react";
import Head from "../common/Head";

import FeaturedArticle from "../common/FeaturedArticle";
import LatestBlogs from "../common/LatestBlogs";
import TopBlogs from "../common/TopBlogs";
import MoreStories from "../common/MoreStories";
import MainSlider from "../common/MainSlider";

const Home = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div>      
        <>
          <Head title="Home Page" />
          <MainSlider />
          <main id="main">
            <LatestBlogs />
            <FeaturedArticle />
            <TopBlogs />
            <MoreStories />
          </main>
        </>
    
    </div>
  );
};

export default Home;
