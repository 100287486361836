import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import logo from '../../logo.png';
import logoleft from '../../logo-left.png';
import logoright from '../../logo-right.png';

import axios from "axios";
const Header = () => {
  const isHomePage = window.location.pathname === '/';
  const pageName = isHomePage ? 'home' : 'inner-page';
  const pageClassName = isHomePage ? '' : 'fixed-top header-scrolled';
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };



  const [categoryData, setCategoryData] = useState([]);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    
    const storedData = localStorage.getItem('categoryData');

    if (storedData) {
      // If data exists in local storage, set state with stored data
      setCategoryData(JSON.parse(storedData));
    } else {
  
    axios
      .get("https://onethirdlive.smacdemo.in/api/categories")
      .then((response) => {
        setCategoryData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }

      function handleScroll() {
        if (window.scrollY > 100) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      }
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  


  return (
   
    <header id="header" className={`${isSticky ? 'fixed-top header-scrolled' : 'fixed-top '} ${pageName} ${pageClassName}`}>
    <div className="container-fluid d-lg-flex align-items-center justify-content-center flex-column headerlogo">
    <Link to={`/`} className="logo" > 
    <img src={logo} alt="Onethrid" className="img-fluid" />
    </Link>
    <div className="logo-left">
    <img src={logoleft} alt="Onethrid" className="img-fluid" />
    </div>
    </div>

    {/* <div className="topmenu">
      <div className="social-links mt-lg-3 text-end">
        <a href="https://www.instagram.com/" className="instagram" rel="noreferrer" > <i className='bx bxl-instagram'></i> </a>
        <a href="https://www.facebook.com/" className="facebook" rel="noreferrer" > <i className='bx bxl-facebook'></i> </a>
        <a href="https://www.twitter.com/" className="twitter" target="_blank" rel="noreferrer" > <i className='bx bxl-twitter'></i> </a>
      </div> 


      <div className="form-group position-relative search-block">
        <div className="form-group has-feedback">
          <input type="text" className="form-control" id="search" placeholder="Search" />
          <span className='bx bx-search'></span>
        </div>
      </div>

    </div> */}
    <div className="logo-right">
    <img src={logoright} alt="Onethrid" className="img-fluid" />
    </div>


    <nav id="navbar" className={`navbar ${menuOpen ? 'navbar-mobile' : ''}`}>
    <ul>
      {categoryData.map(category => (
<li className="nav-item" key={category.id}>
<Link className="nav-link" to={`/category/${category.slug}`} onClick={closeMenu}>
{category.title}
</Link>

</li>
))}
      </ul>
      <i className={`bi ${menuOpen ? 'mobile-nav-toggle bi-x' : 'bi-list mobile-nav-toggle'}`}  onClick={toggleMenu}></i>
    </nav>

  </header>
   
    
  );
};

export default Header;
