import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";


const TopBlogs = (props) => { 
  const category = props.category_id;  
  const [topposts, setTopPosts] = useState([]);
  useEffect(() => {
    if (category) {
      console.log(category);
    axios
      .get(`https://onethirdlive.smacdemo.in/api/topposts?category_id=${category}`)
      .then((response) => {
        setTopPosts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    } else {
      axios
      .get("https://onethirdlive.smacdemo.in/api/topposts")
      .then((response) => {
        setTopPosts(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [category]);

  return (
    <>
<section className="blog-banner pb-0"    data-aos="zoom-in" data-aos-delay='1000'>
  <div className="container-fluid">
    <div className="bannerpost">
      <div className="row">
        <div className="col-lg-8">
        {topposts.slice(0, 1).map((post) => (

          <div className="bannerImg position-relative">
            <img src={post.type_image} className="img-fluid" alt={post.title} />
            
            <Link to={`/blog/${post.slug}`} className="postbanner-content">
              <p className="date"> {post.formatted_date} </p>
              <h3> {post.title} </h3>
              <p className="postbanner-subtitle"></p>
              </Link>
          </div>
           ))}
        </div>
        <div className="col-lg-4">
          <div className="banner-content p-5">

            <h3 className="mb-3 text-center topbl0g-title"> Our Top 5 Blogs </h3>

            <hr />
            {topposts.slice(1).map((post) => (

              <Link to={`/blog/${post.slug}`} className="topList mb-2">
              <div className="row">
                <div className="col-lg-3 col-md-3"> 
                  <img src={post.type_image} className="img-fluid" alt={post.title} />
                </div>
                <div className="col-lg-9 col-md-9">
                  <h5> {post.title} </h5>
                </div>
              </div>
              </Link>
             

            ))}
           

            

          </div>
        </div>
      </div>
    </div>
  </div>
</section>

    </>
  );
};

export default TopBlogs;
