import React, { useState, useEffect } from "react";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './custom.css';
import { Link } from "react-router-dom";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);


const FeaturedArticle = () => {

  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {

    axios
      .get("https://onethirdlive.smacdemo.in/api/getposts?type=2&count=3")
      .then((response) => {
        setSliderData(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <>
    <section id="postbanner" className="post-banner latest-blog pb-0"   data-aos="zoom-in" data-aos-delay='500'>
  <div className="container-fluid">

    <h2 className="mb-lg-5 text-center"> Featured Articles </h2>

    <div className="swiper mySwiper2">
      <div className="swiper-wrapper">

        <div className="postbanner-slider swiper">
        <Swiper
      navigation
      pagination={{ clickable: false }}
      autoplay={{ delay: 3000 }}
    >
            {sliderData.map((post) => ( 
            <SwiperSlide>
            <div className="position-relative">
            <Link 
        to={`/blog/${post.slug}`} >
              <img src={post.featured_image} className="img-fluid" alt={post.title}  />
              </Link>
              <div className="slider-title">
              <Link 
        to={`/blog/${post.slug}`} > {post.title}  </Link></div>
              </div>
      </SwiperSlide>
            ))}
           
            </Swiper>

          </div>
          
        </div>
      </div>
</div>
</section>
    </>
  );
};

export default FeaturedArticle;
